@use 'constants.scss';

// overall app dimensions
body {
	$min-edge-gap: 20px;

	max-width: calc(constants.$max-page-width + $min-edge-gap);
	padding: 0 $min-edge-gap;
	margin: 0 auto;
}

// style header sections (font and size pairing)
header {
	margin: 50px 0 100px;

	h1 {
		margin: 0 0 20px;

		font-family: constants.$sans-serif;
		font-size: constants.$heading-size;
	}

	p {
		margin: 0;

		font-family: constants.$serif;
		font-size: constants.$subheading-size;
	}
}

// style hyperlinks (fade-in underline)
p a {
	color: constants.$maroon;
	border-bottom: 1px solid transparent;
	text-decoration: none;

	transition: 0.3s border;

	&:hover {
		border-bottom: 1px solid constants.$maroon;
	}
}

// style tooltips to show below
.tooltip {
	display: inline-block;
	position: relative;

	color: constants.$maroon;

	cursor: default;

	.tooltip-text {
		$padding: 0.4em;

		position: absolute;
		top: calc(1em + $padding);
		left: 50%;

		padding: $padding calc($padding + 3px);
		border-radius: 4px;

		color: #777;
		background-color: #eee;
		box-shadow: 0px 2px 4px #aaa;
		white-space: nowrap;
		transform: translateX(-50%);

		visibility: hidden;
		opacity: 0;
		transition-duration: 0.2s;
		transition-delay: 0.2s;
	}

	&:hover .tooltip-text {
		visibility: visible;
		opacity: 1;
	}
}
