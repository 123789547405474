@use "constants.scss";

.projGallery {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;

	max-width: 100%;

    // single-column format if screen narrow
    @media only screen and (max-width: constants.$small-page-width) {
        grid-template-columns: repeat(1, 1fr);

        .pg-item {
            border-radius: 0 !important;
        }
    }

    // animate fade-in when gallery media is fully loaded
    &.loaded .pg-item {
        animation-name: fadeIn;
        animation-duration: 500ms;
        animation-fill-mode: forwards;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
