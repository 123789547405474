@use 'constants.scss';

.bio {
	// side-by-side bio format if screen wide enough
	@media only screen and (min-width: constants.$small-page-width) {
		display: flex;
		justify-content: space-around;
		align-items: center;

		width: 100%;
		height: 550px;

		.pfp-container {
			flex-basis: 50px;
			flex-grow: 3;
			height: 100%;
			border-radius: 20px;

			background-color: #eee;
			box-shadow: 8px 8px #9997;

			overflow: hidden;
		}

		.desc-container {
			flex-basis: 0;
			flex-grow: 4;
			margin: 0 1em 0 3em;
		}

		.Typewriter {
			margin-top: 1em;
		}
	}

	// single-column format if narrow screen
	@media only screen and (max-width: constants.$small-page-width) {
		.pfp-container {
			width: 100%;
		}
	}

	// basic font formatting
	.desc-container {
		h2 {
			font-family: constants.$sans-serif;
			font-size: constants.$subheading-size;
		}

		p {
			font-family: constants.$serif;
			font-size: constants.$body-size;
			line-height: 1.5em;
		}
	}

	.Typewriter {
		display: inline-block;

		font-family: constants.$serif;
		font-size: constants.$body-size;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
