@use 'constants.scss';

$ultra-small-page-width: 350px;

.nav-bar {
	display: flex;
	align-items: center;

	padding: 25px 0 0 0;

	.tab-bar {
		display: flex;
		justify-content: space-between;

		flex-basis: max(40%, 300px);
		flex-grow: 0;
		padding: 0 10px 0 0;

		font-family: constants.$sans-serif;

		// omit logo if screen very narrow
		@media only screen and (max-width: $ultra-small-page-width) {
			flew-grow: 1;
			padding: 0;
		}
	}
}

.nav-item {
	flex-grow: 0;

	text-align: right;

	&.active a {
		color: black;
	}

	// animated underline for tab links
	a {
		display: block;
		position: relative;

		padding: 0.2em 0;

		color: constants.$gray;
		text-decoration: none;

		overflow: hidden;

		&:hover {
			cursor: pointer;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;

			width: 100%;
			height: 0.15em;

			background-color: constants.$navy;
			transform: translateX(-101%);

			transition: opacity 300ms, transform 300ms;
		}

		&:hover::after {
			transform: translateX(0);
		}
	}
}

.logo-container {
	$size: min(54px, 13vw);

	flex-basis: 0;
	flex-grow: 1;
	height: $size;

	img {
		height: 100%;
	}
}
