@use 'constants.scss';

footer {
	width: 200px;
	margin: 7.5em auto 2em;

	text-align: center;

	.links {
		display: flex;
		justify-content: space-between;
		align-items: center;

		margin-bottom: 1em;

		a {
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}
	}

	.love {
		color: constants.$maroon;
	}
}
