@use 'constants.scss';

.semester-container {
	margin-bottom: 2em;

	.semester-title {
		font-family: constants.$sans-serif;
		font-size: constants.$subheading-size;
	}

	.semester-class {
		display: block;

		padding: 0 0 0 1.5em;
		font-family: constants.$serif;
		font-size: constants.$body-size;
	}

	.semester-classes {
		position: relative;
		margin-left: 5px;

		background-color: #fff;

		&::before {
			$bar-width: 3px;
			content: '';

			position: absolute;
			top: 0;
			left: -$bar-width;

			width: 15px;
			height: calc(100% + $bar-width);

			background-color: constants.$lightblue;
			filter: brightness(75%);

			z-index: -1;
		}
	}
}
