/* styles grouping and priority

- Variables, content
- Display, justify-content, align-items, grid-stuff, position, top, left, bottom, right
- Flex-basis, flex-grow, width, height, padding, margin, border-radius, object-fit
- Color, background-color, border-stuff, box-shadow, font-stuff, filter, transform
- Other/miscellaneous, opacity, overflow, transition, z-index

*/

// main
$max-page-width: 900px;
$small-page-width: 600px;

// text
$cursive: 'Licorice', cursive;
$serif: 'Lora', serif;
$sans-serif: 'Open Sans', sans-serif;

$heading-size: 2.66rem;
$subheading-size: 1.33rem;
$body-size: 1rem;
$mini-size: 0.8rem;

// colors
$gray: #888;

$navy: #003d61;
$maroon: #ad0000;
$lightblue: #b5efff;
