@use 'constants.scss';

$overlay-padding: 20px;
$overlay-fade-duration: 250ms;

.pg-item {
	position: relative;

	max-width: 100%;
	margin-bottom: 20px;
	border-radius: 10px;

	opacity: 0;
	overflow: hidden;
	transition: opacity 0.25s;

	.pg-overlayHeading {
		margin: 0;

		font-family: constants.$sans-serif;
		font-size: constants.$subheading-size;
		font-weight: regular;
	}

	.pg-overlaySubheading {
		margin: 0 0 1em;

		font-family: constants.$sans-serif;
		font-size: constants.$mini-size;
		font-weight: bold;
	}

	.pg-overlayBody {
		font-family: constants.$serif;
		font-size: constants.$mini-size;
	}

	.pg-thumbnail {
		display: block;
        
        width: 100%;
		max-width: 100%;
	}

	.pg-overlay {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;

		padding: $overlay-padding;
		margin: 8px;
		border-radius: 5px;

		color: black;
		background-color: #fffc;

		visibility: hidden;
		opacity: 0;
		transition: visibility 0s linear $overlay-fade-duration, opacity $overlay-fade-duration;
		z-index: 2;

		// alternative: opacity fade-in/out
		// opacity: 0;
		// overflow-y: hidden;
		// user-select: none;
		// cursor: default;

		&.full {
			top: 0;
		}
	}

	&.active {
		box-shadow: 1px 2px 4px #888c;
	}

	&:hover,
	&.active {
		// keep open the appropriate overlay if item is active
		.pg-overlay.active {
			visibility: visible;
			opacity: 1;
			transition: visibility 0s linear 0s, opacity $overlay-fade-duration;
			z-index: 3;

			// alternative: opacity fade-in/out
			// opacity: 1;
			// overflow-y: scroll;
			// user-select: initial;
			// cursor: initial;
		}

		.pg-buttons {
			visibility: visible;
		}
	}

	.pg-buttons {
		position: absolute;
		bottom: $overlay-padding - 5px;
		right: $overlay-padding - 3px;

		visibility: hidden;
		z-index: 5;

		img {
			width: 30px;

			cursor: pointer;
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}
	}
}
